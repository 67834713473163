import { Edge, Node, NodeProps } from "reactflow";
import { FLOW_ACTIONS } from "../../../../../common/constants/campaign";
import {
  BranchByDataActionOptions,
  BranchByFilterActionOptions,
  BranchByTokenActionOptions,
  BranchByValueActionOptions,
  FlowGroupActionOptions,
  OperatorDetails,
  OperatorList,
  ValueTypes,
} from "../../../../../common/types/campaign";
import {
  ActionNodeArgs,
  AddNodeArgs,
  ChildIdentifiers,
  ComputeOperatorDetails,
  EdgeDataProps,
  EdgeLabelNodeArgs,
  EDGE_LABEL_VARIANTS,
  ExitCriteriaLinkData,
  FlowEdge,
  FlowGraph,
  FlowNodeAction,
  NodeHelperProps,
} from "../../../../../common/types/flow";
import {
  BRANCH_BY_FILTER_LABEL,
  gotoEdgeSelectedStyle,
  edgeStyle,
  HANDLE_IDS,
  WIDGET_OPTIONS_DETAILS,
  defaultEdgeMarkerProps,
  INIT_FLOW_NODE_ID,
  NODE_TYPES,
} from "./constants";
import ELK, { ElkNode } from "elkjs";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { toast } from "react-toastify";
import { isArgumentMany } from "../../../../../common/helper/commonHelper";

const nonActionNodesHeight = 60;

const edgeLabelProps = {
  type: "custom",
  pathOptions: { offset: 5, borderRadius: 15 },
};

function getWidthOfNode(
  type: string,
  data?: ActionNodeArgs | AddNodeArgs | EdgeLabelNodeArgs
) {
  if (Object.values(FLOW_ACTIONS).includes(type as FLOW_ACTIONS)) {
    if (type === FLOW_ACTIONS.GOTO) return 250;
    return 450;
  } else if (type === NODE_TYPES.EDGE_LABEL) {
    return 384;
  } else if (type === NODE_TYPES.INSERT_NODE) {
    if ((data as AddNodeArgs).isFlowEmpty) {
      return 448;
    } else {
      return 28;
    }
  }
  return 384;
}

function getHeightOfNode(action?: FlowNodeAction) {
  if (action) {
    if (
      [FLOW_ACTIONS.BRANCH_BY_TOKEN, FLOW_ACTIONS.BRANCH_BY_VALUE].includes(
        action.action_type
      ) &&
      !(action.action_options as BranchByDataActionOptions).conditions?.length
    ) {
      return 200;
    }
    return (
      WIDGET_OPTIONS_DETAILS[action.action_type as FLOW_ACTIONS].height + 20
    );
  }
  return nonActionNodesHeight;
}

// Function to layout the graph and set it's x-y position
export async function getLayoutedElements(
  nodes: Node[],
  edges: Edge[],
  xPos: number
) {
  const elk = new ELK();
  const childrenList: ElkNode[] = [];
  const basicLayoutOptions = {
    "elk.algorithm": "layered",
    "elk.direction": "DOWN",
    "elk.layered.nodePlacement.bk.fixedAlignment": "BALANCED",
    "elk.layered.crossingMinimization.forceNodeModelOrder": "true",
    "elk.hierarchyHandling": "INCLUDE_CHILDREN",
    "elk.alignment": "CENTER",
    "elk.layered.spacing.nodeNodeBetweenLayers": "60",
    "elk.spacing.nodeNode": "200",
  };
  const groupLayoutOptions = {
    hierarchyHandling: "SEPARATE_CHILDREN",
    "elk.direction": "DOWN",
    "elk.algorithm": "layered",
    "elk.layered.nodePlacement.bk.fixedAlignment": "BALANCED",
    "elk.layered.crossingMinimization.forceNodeModelOrder": "true",
    "elk.graphviz.overlapMode": "SCALEXY",
    "elk.padding": "[left=50, top=70, right=50, bottom=50]",
    "elk.layered.spacing.nodeNodeBetweenLayers": "50",
    "elk.spacing.nodeNode": "50",
  };
  nodes.forEach((node) => {
    const height = getHeightOfNode(node.data.action);
    const width = getWidthOfNode(node.type!, node.data);

    if (node.parentNode) {
      const parentIndex = childrenList.findIndex(
        (child) => child.id === node.parentNode
      );
      if (parentIndex !== -1) {
        childrenList[parentIndex].children?.push({
          id: node.id,
          width: width,
          height: height,
        });
      }
    } else {
      childrenList.push({
        id: node.id,
        width: width,
        height: height,
        layoutOptions: groupLayoutOptions,
        children: [],
      });
    }
  });

  const graph = {
    id: "root",
    layoutOptions: basicLayoutOptions,
    children: childrenList,
    edges: edges.map((edge) => {
      return {
        id: edge.id,
        sources: [edge.source],
        targets: [edge.target],
        renderBehindTheNode: false,
      };
    }),
  };
  try {
    const newGraph = await elk.layout(graph);
    const initNodePosition = newGraph.children?.find(
      (node) => node.id === getNodeId(NODE_TYPES.DYNAMIC_LIST_COUNT, "")
    );
    const offSet = xPos - (initNodePosition?.x ?? 0) - 250;
    nodes.forEach((node) => {
      const isGroup = node.data?.action?.action_type === FLOW_ACTIONS.GROUP;
      let xPos = 0;
      let yPos = 0;
      let height: number | undefined = undefined;
      let width: number | undefined = undefined;
      if (node.parentNode) {
        const parent = newGraph.children?.find(
          (child: any) => child.id === node.parentNode
        );
        if (parent) {
          const nodeWithPosition = parent.children?.find(
            (child: any) => child.id === node.id
          );
          xPos = nodeWithPosition!.x! - offSet;
          yPos = nodeWithPosition!.y!;
        }
      } else {
        const nodeWithPosition = newGraph.children?.find(
          (nd) => nd.id === node.id
        );
        xPos = nodeWithPosition!.x!;
        yPos = nodeWithPosition!.y!;
        height = nodeWithPosition!.height!;
        width = nodeWithPosition!.width!;
        if (isGroup) {
          xPos = xPos - 1; // border of group is not taken into account when calculating position
        }
      }
      node.position = {
        x: xPos + offSet,
        y: yPos,
      };
      if (height && width) {
        node.data = { ...node.data, height, width };
        node.height = height;
        node.width = width;
      }
      return node;
    });
  } catch (err) {
    toast.error("Error on layout");
  }

  return { nodes, edges };
}

// helper function to break edges and add edge label node
function breakAndAddEdgeLabel(
  nodes: Node[],
  edges: Edge[],
  parent: string,
  labelProps: EdgeLabelNodeArgs,
  childBranchId: string,
  child: string | null,
  groupParent?: string
) {
  nodes.push(addNewEdgeLabel(labelProps, childBranchId, groupParent));
  edges.push(
    newEdge({
      source: parent,
      target: getNodeId(NODE_TYPES.EDGE_LABEL, childBranchId),
      data: {
        prevActionId: parent,
        branchId: childBranchId,
        isStepAddable: false,
        groupId: groupParent,
      },
    })
  );
  edges.push(
    newEdge({
      source: getNodeId(NODE_TYPES.EDGE_LABEL, childBranchId),
      target:
        child ??
        getNodeId(
          NODE_TYPES.INSERT_NODE,
          getInsertNodeUniqueId(childBranchId, groupParent)
        ),
      data: {
        prevActionId: parent,
        branchId: childBranchId,
        isStepAddable: !!child,
        groupId: groupParent,
      },
    })
  );
}

// helper function to add edge as a node and if no child it will add edge with `add flow step button`
function insertEdgeLabel(
  nodes: Node[],
  edges: Edge[],
  parent: string,
  labelProps: EdgeLabelNodeArgs,
  childBranchId: string,
  child?: string,
  groupParent?: string
) {
  if (!child) {
    nodes.push(addNewWidgetNode(parent, childBranchId, false, groupParent));
  }
  breakAndAddEdgeLabel(
    nodes,
    edges,
    parent,
    labelProps,
    childBranchId,
    child ?? null,
    groupParent
  );
}

function convertToNodeType(
  action: FlowNodeAction,
  parent?: string,
  type?: string
) {
  const pos = { x: 0, y: 0 };
  return {
    id: getNodeId(NODE_TYPES.ACTION_NODE, action.action_id),
    type: type ?? NODE_TYPES.ACTION_NODE,
    position: pos,
    data: {
      action: action,
      groupId: parent,
    },
    parentNode: parent,
    expandParent: true,
  };
}

export function getOperatorDetailsForBranching(
  type: FLOW_ACTIONS.BRANCH_BY_TOKEN | FLOW_ACTIONS.BRANCH_BY_VALUE,
  computeBooleanOperators: ComputeOperatorDetails[],
  operators: OperatorList | null,
  operator: string
) {
  if (type === FLOW_ACTIONS.BRANCH_BY_TOKEN) {
    return computeBooleanOperators.find((op) => op.id === operator);
  } else {
    return getOldOperatorDetails(operators, operator);
  }
}

function handleBranchByFilter(
  id: string,
  actionOptions: BranchByFilterActionOptions,
  initialNodes: FlowNodeAction[],
  sortedNodes: Node[],
  sortedEdges: Edge[],
  children: {
    [actionId: string]: ChildIdentifiers[];
  },
  gotoLinks: Edge[],
  groupExits: Edge[],
  groupParent?: string
) {
  function continueTraversal(
    label: BRANCH_BY_FILTER_LABEL,
    branchId: string,
    child?: ChildIdentifiers
  ) {
    insertEdgeLabel(
      sortedNodes,
      sortedEdges,
      id,
      {
        variant: EDGE_LABEL_VARIANTS.BRANCH_BY_FILTER,
        label,
      },
      branchId,
      child?.nodeId,
      groupParent
    );
    if (child)
      preorderTraversal(
        child.nodeId,
        initialNodes,
        sortedNodes,
        sortedEdges,
        children,
        gotoLinks,
        groupExits,
        groupParent
      );
  }

  if (actionOptions?.condition?.conditions?.length) {
    // Branch out only when condition is set
    const primaryChild = children[id]?.find(
      (child) => child.branchId === actionOptions.primary_branch_id
    );
    const secondaryChild = children[id]?.find(
      (child) => child.branchId === actionOptions.default_branch_id
    );
    //primary child is true branch
    continueTraversal(
      BRANCH_BY_FILTER_LABEL.TRUE,
      actionOptions.primary_branch_id!,
      primaryChild
    );
    //secondary child is false branch
    continueTraversal(
      BRANCH_BY_FILTER_LABEL.FALSE,
      actionOptions.default_branch_id!,
      secondaryChild
    );
  }
}

function handleBranchByData(
  id: string,
  type: FLOW_ACTIONS.BRANCH_BY_TOKEN | FLOW_ACTIONS.BRANCH_BY_VALUE,
  actionOptions: BranchByDataActionOptions,
  initialNodes: FlowNodeAction[],
  sortedNodes: Node[],
  sortedEdges: Edge[],
  children: {
    [actionId: string]: ChildIdentifiers[];
  },
  gotoLinks: Edge[],
  groupExits: Edge[],
  groupParent?: string
) {
  function continueTraversal(
    label: EdgeLabelNodeArgs,
    branchId: string,
    child?: ChildIdentifiers
  ) {
    insertEdgeLabel(
      sortedNodes,
      sortedEdges,
      id,
      label,
      branchId,
      child?.nodeId,
      groupParent
    );
    if (child)
      preorderTraversal(
        child.nodeId,
        initialNodes,
        sortedNodes,
        sortedEdges,
        children,
        gotoLinks,
        groupExits,
        groupParent
      );
  }

  actionOptions?.conditions?.forEach(({ condition, branch_id }) => {
    const child = children[id]?.find((child) => child.branchId === branch_id);
    // Branch out for each condition
    continueTraversal(
      {
        variant: EDGE_LABEL_VARIANTS.BRANCH_BY_DATA,
        type,
        operator: condition.operator ?? "",
        value: condition.value,
        isDefault: false,
      },
      branch_id ?? "",
      child
    );
  });
  // Branch out for the dafault branch
  const tokenChild = children[id]?.find(
    (child) => child.branchId === actionOptions.default_branch_id
  );
  continueTraversal(
    {
      variant: EDGE_LABEL_VARIANTS.BRANCH_BY_DATA,
      type,
      operator: "",
      value: [],
      isDefault: true,
    },
    actionOptions.default_branch_id!,
    tokenChild
  );
}

function handleGroup(
  id: string,
  branchId: string,
  actionOptions: FlowGroupActionOptions,
  initialNodes: FlowNodeAction[],
  sortedNodes: Node[],
  sortedEdges: Edge[],
  children: {
    [actionId: string]: ChildIdentifiers[];
  },
  gotoLinks: Edge[],
  groupExits: Edge[],
  groupParent?: string
) {
  // Inside group
  let isGroupEmpty = true;
  if (actionOptions.children?.nodes.length) {
    const subFlowNodes = actionOptions.children.nodes.filter(
      (node) => !node.is_deleted
    );
    const firstNode = subFlowNodes.find((node) => node.flow_start_action);
    if (firstNode) {
      preorderTraversal(
        firstNode.action_id,
        subFlowNodes,
        sortedNodes,
        sortedEdges,
        children,
        gotoLinks,
        groupExits,
        id
      );
      isGroupEmpty = false;
    }
  }
  if (isGroupEmpty) {
    sortedNodes.push(
      addNewWidgetNode(INIT_FLOW_NODE_ID, INIT_FLOW_NODE_ID, true, id)
    );
  }
  // Outside group
  let hasDefaultChild = false;
  if (children[id]?.length) {
    let left = 0,
      right = 0;
    children[id].forEach((child, index) => {
      if (child.edgeConditionId) {
        const isVisited = sortedNodes.some((node) => node.id === child.nodeId);
        const isSameBranch = child.branchId === branchId;
        groupExits.push(
          newEdge({
            source: id,
            target: child.nodeId,
            data: {
              prevActionId: id,
              branchId: child.branchId,
              conditionId: child.edgeConditionId,
              isStepAddable: false,
            },
            isMarker: true,
            sourceHandle:
              isVisited || isSameBranch
                ? `${HANDLE_IDS.LEFT_SOURCE}-${left++}`
                : `${HANDLE_IDS.RIGHT_SOURCE}-${right++}`,
            targetHandle:
              isVisited && !isSameBranch
                ? HANDLE_IDS.RIGHT_TARGET
                : HANDLE_IDS.LEFT_TARGET,
            type: "smart",
            animated: true,
            className: "exit-edge",
          })
        );
      } else {
        hasDefaultChild = true;
        sortedEdges.push(
          newEdge({
            source: id,
            target: child.nodeId,
            data: {
              prevActionId: id,
              branchId: children[id][0].branchId,
              isStepAddable: true,
              groupId: groupParent,
            },
          })
        );
        preorderTraversal(
          child.nodeId,
          initialNodes,
          sortedNodes,
          sortedEdges,
          children,
          gotoLinks,
          groupExits,
          groupParent
        );
      }
    });
  }
  if (!hasDefaultChild) {
    sortedNodes.push(
      addNewWidgetNode(
        id ?? INIT_FLOW_NODE_ID,
        branchId ?? INIT_FLOW_NODE_ID,
        !initialNodes?.length,
        groupParent
      )
    );
    sortedEdges.push(
      newEdge({
        source: id,
        target: getNodeId(
          NODE_TYPES.INSERT_NODE,
          getInsertNodeUniqueId(branchId ?? INIT_FLOW_NODE_ID, groupParent)
        ),
        data: {
          prevActionId: id,
          branchId: branchId,
          isStepAddable: false,
        },
      })
    );
  }
}

// function for preorder traversal(recursive) to add labelled edge, `add flow step button` and sort the nodes.
export function preorderTraversal(
  id: string,
  initialNodes: FlowNodeAction[],
  sortedNodes: Node[],
  sortedEdges: Edge[],
  children: {
    [actionId: string]: ChildIdentifiers[];
  },
  gotoLinks: Edge[],
  groupExits: Edge[],
  groupParent?: string
) {
  const currentNode = initialNodes.find((node) => node.action_id === id);
  const isDlNode = id === getNodeId(NODE_TYPES.DYNAMIC_LIST_COUNT, "");
  if (!isDlNode && !currentNode) return;
  if (!isDlNode)
    sortedNodes.push(
      convertToNodeType(currentNode!, groupParent, currentNode?.action_type)
    );

  switch (currentNode?.action_type) {
    case FLOW_ACTIONS.BRANCH_BY_FILTER:
      const filterActionOptions =
        currentNode.action_options as BranchByFilterActionOptions;
      handleBranchByFilter(
        id,
        filterActionOptions,
        initialNodes,
        sortedNodes,
        sortedEdges,
        children,
        gotoLinks,
        groupExits,
        groupParent
      );
      return;

    case FLOW_ACTIONS.BRANCH_BY_TOKEN:
      const tokenActionOptionData =
        currentNode.action_options as BranchByTokenActionOptions;
      handleBranchByData(
        id,
        FLOW_ACTIONS.BRANCH_BY_TOKEN,
        tokenActionOptionData,
        initialNodes,
        sortedNodes,
        sortedEdges,
        children,
        gotoLinks,
        groupExits,
        groupParent
      );
      return;

    case FLOW_ACTIONS.BRANCH_BY_VALUE:
      const valueActionOptionData =
        currentNode.action_options as BranchByValueActionOptions;
      handleBranchByData(
        id,
        FLOW_ACTIONS.BRANCH_BY_VALUE,
        valueActionOptionData,
        initialNodes,
        sortedNodes,
        sortedEdges,
        children,
        gotoLinks,
        groupExits,
        groupParent
      );
      return;

    case FLOW_ACTIONS.GOTO:
      if (children[id]?.length) {
        const child = children[id][0];
        const isVisited = sortedNodes.some((node) => node.id === child.nodeId);
        gotoLinks.push(
          newEdge({
            source: id,
            target: child.nodeId,
            data: {
              prevActionId: id,
              branchId: children[id][0].branchId,
              isStepAddable: false,
              groupId: groupParent,
            },
            isMarker: true,
            targetHandle: isVisited
              ? HANDLE_IDS.RIGHT_TARGET
              : HANDLE_IDS.LEFT_TARGET,
            type: "smart",
            className: "goto-edge",
          })
        );
      }
      return;
    case FLOW_ACTIONS.GROUP:
      const flowGroupActionOptions =
        currentNode.action_options as FlowGroupActionOptions;
      handleGroup(
        id,
        currentNode.branch_id,
        flowGroupActionOptions,
        initialNodes,
        sortedNodes,
        sortedEdges,
        children,
        gotoLinks,
        groupExits,
        groupParent
      );
      return;
    default:
      // it's not a branching flow step
      if (children[id]?.length) {
        const child = children[id][0];
        sortedEdges.push(
          newEdge({
            source: id,
            target: child.nodeId,
            data: {
              prevActionId: isDlNode ? INIT_FLOW_NODE_ID : id,
              branchId: isDlNode ? INIT_FLOW_NODE_ID : child.branchId,
              isStepAddable: true,
              groupId: groupParent,
            },
          })
        );
        preorderTraversal(
          child.nodeId,
          initialNodes,
          sortedNodes,
          sortedEdges,
          children,
          gotoLinks,
          groupExits,
          groupParent
        );
      } else {
        sortedNodes.push(
          addNewWidgetNode(
            currentNode?.action_id ?? INIT_FLOW_NODE_ID,
            currentNode?.branch_id ?? INIT_FLOW_NODE_ID,
            !initialNodes?.length,
            groupParent
          )
        );
        sortedEdges.push(
          newEdge({
            source: id,
            target: getNodeId(
              NODE_TYPES.INSERT_NODE,
              getInsertNodeUniqueId(
                currentNode?.branch_id ?? INIT_FLOW_NODE_ID,
                groupParent
              )
            ),
            data: {
              prevActionId: id,
              branchId: currentNode?.branch_id,
              isStepAddable: false,
              groupId: groupParent,
            },
          })
        );
      }
      return;
  }
}

// Since insert node is added from frontend, it needs a unique id.
// branchid+groupId can be a primary key to identify the node
function getInsertNodeUniqueId(branchId: string, groupId = "") {
  return branchId + groupId;
}

export function getNodeId(nodeType: NODE_TYPES, id: string) {
  const namePrefix = {
    [NODE_TYPES.ACTION_NODE]: "",
    [NODE_TYPES.INSERT_NODE]: "node-add-under-",
    [NODE_TYPES.DYNAMIC_LIST_COUNT]: "node-dl-count",
    [NODE_TYPES.EDGE_LABEL]: "node-edge-label",
  };
  return namePrefix[nodeType] + id;
}

export function getEdgeId(sourceId: string, targetId: string, salt = "") {
  return `n2n%${sourceId}%${targetId}%${salt}`;
}

function addNewWidgetNode(
  actionId: string,
  branchId: string,
  isFlowEmpty = false,
  parent?: string
) {
  return {
    id: getNodeId(
      NODE_TYPES.INSERT_NODE,
      getInsertNodeUniqueId(branchId, parent)
    ),
    type: NODE_TYPES.INSERT_NODE,
    position: { x: 0, y: 0 },
    data: {
      isFlowEmpty: isFlowEmpty,
      parentActionId: actionId,
      branchId,
      groupId: parent,
    },
    parentNode: parent,
  };
}

function addNewEdgeLabel(
  labelProps: EdgeLabelNodeArgs,
  branchId: string,
  parent?: string
) {
  return {
    id: getNodeId(NODE_TYPES.EDGE_LABEL, branchId),
    type: NODE_TYPES.EDGE_LABEL,
    position: { x: 0, y: 0 },
    data: labelProps,
    parentNode: parent,
  };
}

export function dynamicListCountNode() {
  return {
    id: getNodeId(NODE_TYPES.DYNAMIC_LIST_COUNT, ""),
    type: NODE_TYPES.DYNAMIC_LIST_COUNT,
    position: {
      x: 0,
      y: 0,
    },
    data: {},
  };
}

export function newEdge(edgeData: {
  source: string;
  target: string;
  label?: string;
  data?: EdgeDataProps;
  isMarker?: boolean;
  type?: string;
  sourceHandle?: string;
  targetHandle?: HANDLE_IDS;
  animated?: boolean;
  className?: string;
}) {
  return {
    ...edgeLabelProps,
    ...edgeData,
    style: edgeStyle,
    className: edgeData.className,
    id: getEdgeId(
      edgeData.source,
      edgeData.target,
      edgeData.data?.conditionId ?? edgeData.data?.branchId
    ),
    markerEnd: edgeData.isMarker
      ? {
          ...defaultEdgeMarkerProps,
          orient: edgeData.targetHandle?.includes("left") ? "0deg" : "180deg",
        }
      : undefined,
    targetHandle: edgeData.targetHandle ?? HANDLE_IDS.TOP_TARGET,
    sourceHandle: edgeData.sourceHandle ?? HANDLE_IDS.BOTTOM_SOURCE,
    focusable: true,
  };
}

export function valueFieldsReadonly(
  value: ValueTypes,
  argumentTypes: (string | string[])[] | null,
  isMany = false
) {
  if (isMany) {
    return value.join(", ");
  }
  let valueString = "";
  argumentTypes &&
    argumentTypes.forEach((type, i) => {
      if (typeof type === "string") {
        valueString += `${i !== 0 ? " and" : ""} ${value[i]}`;
      } else if (typeof type === "object") {
        valueString = valueString + ` ${value[i]}`;
      } else {
        throw new Error("Uknown Argument Type: " + type);
      }
    });
  return valueString;
}

export function operatorDisplayName(
  operatorDetails: OperatorDetails | ComputeOperatorDetails | undefined,
  isOldOperator = false
) {
  if (!operatorDetails) return "";
  if (isOldOperator)
    return (
      (operatorDetails as OperatorDetails).display ??
      (operatorDetails as OperatorDetails).id
    );
  return (operatorDetails as ComputeOperatorDetails).display_name;
}

export function getBranchingOperationLabel(
  operatorDetails: OperatorDetails | ComputeOperatorDetails,
  value: ValueTypes,
  isOldOperator = false
) {
  const argumentTypes = isOldOperator
    ? (operatorDetails as OperatorDetails)?.arguments_types
    : Array(
        Number((operatorDetails as ComputeOperatorDetails)?.operands ?? 1) - 1
      ).fill("type");

  const isMany =
    isOldOperator &&
    isArgumentMany((operatorDetails as OperatorDetails).arguments);
  return `${operatorDisplayName(
    operatorDetails,
    isOldOperator
  )} ${valueFieldsReadonly(value, argumentTypes, isMany)}`;
}

//TODO: discuss with backend for having consistency in operators
export function getOldOperatorDetails(
  operatorsList: OperatorList | null,
  operator: string | null
) {
  if (operatorsList) {
    let details;
    Object.values(operatorsList).forEach((op) => {
      const oper = Object.entries(op).find(([id, _]) => id === operator);
      if (oper) {
        details = oper[1];
      }
    });
    return details;
  } else return undefined;
}

export function findAncestors(
  id: string,
  ancestorGraph: { [id: string]: string[] }
) {
  const ancestorList: string[] = [];
  const idQueue: string[] = [id];
  while (idQueue.length) {
    const current = idQueue.shift() as string;
    if (ancestorGraph[current] && ancestorGraph[current].length) {
      ancestorGraph[current].forEach((parent) => {
        ancestorList.push(parent);
        idQueue.push(parent);
      });
    }
  }
  return ancestorList;
}

export function appendGotoEdge(
  sortedNodes: Node[],
  sortedEdges: Edge[],
  gotoLinks: Edge[],
  ancestor: { [key: string]: string[] }
) {
  let nodes = cloneDeep(sortedNodes);
  let edges = cloneDeep(sortedEdges);

  let childLessGoto = "";
  let childLessGotoGroup = "";

  gotoLinks.forEach((gotoEdge) => {
    edges.push(gotoEdge);
  });

  nodes = nodes.map((node) => {
    if (
      node.data?.action?.action_type === FLOW_ACTIONS.GOTO &&
      !gotoLinks.some((edge) => edge.source === node.id)
    ) {
      childLessGoto = node.id;
      childLessGotoGroup = node.data.groupId;
    }
    node.data = {
      ...node.data,
      gotoChild: gotoLinks.find((edge) => edge.source === node.id)?.target,
    };
    return node;
  });

  if (childLessGoto) {
    const candidates = findAncestors(childLessGoto, ancestor);
    nodes = nodes.map((node) => {
      const isCandidate =
        !candidates.includes(node.id) &&
        node.data.groupId === childLessGotoGroup;
      node.data = {
        ...node.data,
        isCandidate,
        selectedGoto: childLessGoto || undefined,
        lock: true,
      };
      return node;
    });
    edges = edges.map((edge) => {
      const isHighlighted = edge.id.includes(childLessGoto);
      edge.style = isHighlighted ? gotoEdgeSelectedStyle : edgeStyle;
      edge.data.readonly = true;
      return edge;
    });
  }
  return { nodes, edges };
}

export function appendExitLinks(
  sortedNodes: Node[],
  sortedEdges: Edge[],
  groupExits: Edge[],
  ancestor: { [key: string]: string[] }
) {
  let nodes = cloneDeep(sortedNodes);
  let edges = cloneDeep(sortedEdges);

  let childLessExit = {
    actionId: "",
    condition: "",
  };

  groupExits.forEach((exitLink) => {
    edges.push(exitLink);
  });

  nodes = nodes.map((node) => {
    let childLessCondition: string | undefined = "";
    let exitLinkData: ExitCriteriaLinkData = {};
    if (node.data?.action?.action_type === FLOW_ACTIONS.GROUP) {
      let exitCriteria = (
        node.data.action?.action_options as FlowGroupActionOptions
      ).exit_criteria;
      if (exitCriteria) {
        exitCriteria.forEach((condition) => {
          const exitLink = groupExits
            .filter((edge) => edge.source === node.id)
            .find((edge) => edge.data.conditionId === condition.condition_id);
          if (!exitLink) {
            childLessExit = {
              actionId: node.id,
              condition: condition.condition_id!,
            };
            childLessCondition = condition.condition_id;
          } else {
            exitLinkData[condition.condition_id!] = {
              sourceHandle: exitLink?.sourceHandle!,
              target: {
                actionId: exitLink?.target ?? "",
                branchId: exitLink?.data.branchId ?? "",
              },
            };
          }
        });
      }
    }
    node.data = {
      ...node.data,
      childLessExit: childLessCondition,
      exitLinkData: !isEmpty(exitLinkData) ? exitLinkData : undefined,
    };
    return node;
  });

  if (childLessExit.actionId) {
    const ancestors = findAncestors(childLessExit.actionId, ancestor);
    nodes = nodes.map((node) => {
      const isCandidate =
        node.id !== childLessExit.actionId &&
        !ancestors.includes(node.id) &&
        node.data.groupId === undefined &&
        !groupExits.some(
          (edge) =>
            edge.source === childLessExit.actionId && edge.target === node.id
        );
      node.data = {
        ...node.data,
        isCandidate,
        selectedExit: {
          actionId: childLessExit.actionId,
          conditionId: childLessExit.condition,
        },
        lock: true,
      };
      return node;
    });
  }
  return { nodes, edges };
}

export function appendProps(
  nodes: Node[],
  nodeProps: NodeHelperProps,
  edges: Edge[],
  edgeProps: EdgeDataProps
) {
  const updatedNodes = nodes.map((node) => {
    const newNode = cloneDeep(node);
    newNode.data = {
      ...newNode.data,
      props: { ...(newNode.data.props ?? {}), ...nodeProps },
    };
    return newNode;
  });
  const updatedEdges = edges.map((edge) => {
    const newEdge = cloneDeep(edge);
    newEdge.data = { ...newEdge.data, ...edgeProps };
    return newEdge;
  });
  return { nodes: updatedNodes, edges: updatedEdges };
}

export function createAdjacentGraphs(
  nodes: FlowNodeAction[],
  edges: FlowEdge[]
) {
  const childGraph: {
    [parentActionId: string]: ChildIdentifiers[];
  } = {};
  const ancestorGraph: {
    [childActionId: string]: string[];
  } = {};
  let allEdges = cloneDeep(edges);
  let allNodes = cloneDeep(nodes);

  if (nodes.length) {
    const firstNode = nodes.find((node) => node.flow_start_action);
    if (firstNode)
      childGraph[getNodeId(NODE_TYPES.DYNAMIC_LIST_COUNT, "")] = [
        {
          nodeId: getNodeId(NODE_TYPES.ACTION_NODE, firstNode?.action_id),
          branchId: firstNode?.branch_id,
          edgeConditionId: "",
        },
      ];
  }

  nodes.forEach((node) => {
    if (node.action_type === FLOW_ACTIONS.GROUP) {
      const subFlow = (node.action_options as FlowGroupActionOptions)?.children;
      const subFlowEdges = subFlow?.links;
      const subFlowNodes = subFlow?.nodes;
      if (subFlowEdges) allEdges = [...allEdges, ...subFlowEdges];
      if (subFlowNodes) allNodes = [...allNodes, ...subFlowNodes];
    }
  });

  allEdges.forEach((edge) => {
    const child = allNodes.find((node) => node.action_id === edge.target)!;
    const childDetails = {
      nodeId: child?.action_id,
      branchId: child?.branch_id ?? "",
      edgeConditionId: edge.attributes?.condition_id ?? "",
    };
    const ancestor = getNodeId(NODE_TYPES.ACTION_NODE, edge.source);

    childGraph[ancestor] = [...(childGraph[ancestor] ?? []), childDetails];
    ancestorGraph[child?.action_id] = [
      ...(ancestorGraph[child?.action_id] ?? []),
      ancestor,
    ];
  });
  return { childGraph, ancestorGraph };
}

export function isActionDataSame(
  prevProps: NodeProps<ActionNodeArgs>,
  newProps: NodeProps<ActionNodeArgs>
) {
  return isEqual(prevProps.data, newProps.data);
}

export function fetchMiniMapNodeColor(type?: FLOW_ACTIONS | NODE_TYPES) {
  switch (type) {
    case FLOW_ACTIONS.GOTO:
      return "transparent";
    default:
      if (type && Object.values(FLOW_ACTIONS).includes(type as FLOW_ACTIONS)) {
        return `${WIDGET_OPTIONS_DETAILS[type as FLOW_ACTIONS]?.color}20`;
      } else {
        return "transparent";
      }
  }
}

export function fetchMiniMapStrokeColor(type?: FLOW_ACTIONS | NODE_TYPES) {
  if (
    !Object.values(FLOW_ACTIONS).includes(type as FLOW_ACTIONS) ||
    type === FLOW_ACTIONS.GOTO
  ) {
    return "transparent";
  }
  return WIDGET_OPTIONS_DETAILS[type as FLOW_ACTIONS]?.color;
}

export function filterDeleteFlowSteps(flow: FlowGraph | null): FlowGraph {
  if (flow) {
    const actions = cloneDeep(flow);
    const nodes = actions.nodes.filter((action) => !action.is_deleted);
    actions.nodes = nodes;
    return actions;
  } else {
    return {
      nodes: [],
      links: [],
    };
  }
}

export function initialiseValidationForActions(flow: FlowGraph) {
  const flowValidity: { [actionId: string]: boolean } = {};
  flow.nodes.forEach((action) => {
    flowValidity[action.action_id] = true;
    if (action.action_type === FLOW_ACTIONS.GROUP) {
      const actionOptions = action.action_options as FlowGroupActionOptions;
      actionOptions.children?.nodes.forEach((node) => {
        flowValidity[node.action_id] = true;
      });
    }
  });
  return flowValidity;
}

import { FormControl, FormLabel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { DESTINATION_TYPES } from "../../common/types/unifiedMapping";
import DropdownWithSearch from "../DropdownWithSearch";
import IModal from "../IModal";
import InputFieldWithError from "../InputFieldWithError";

const OPTIONS = [
  { label: "String", value: DESTINATION_TYPES.STRING, icon: FaUser },
  { label: "Boolean", value: DESTINATION_TYPES.BOOLEAN, icon: FaUser },
  { label: "Integer", value: DESTINATION_TYPES.INTEGER, icon: FaUser },
  { label: "Float", value: DESTINATION_TYPES.FLOAT, icon: FaUser },
  { label: "Date Time", value: DESTINATION_TYPES.DATETIME, icon: FaUser },
];

export function AddNewFieldModal({
  isOpen,
  onCancel,
  save,
}: {
  isOpen: boolean;
  onCancel: () => void;
  save: ({ field, type }: { field: string; type: DESTINATION_TYPES }) => void;
}) {
  useEffect(() => {
    setField("");
    setType(OPTIONS[0]);
  }, [isOpen]);

  const [field, setField] = useState("");
  const [type, setType] = useState<(typeof OPTIONS)[number]>(OPTIONS[0]);

  return (
    <IModal
      isOpen={isOpen}
      onClose={onCancel}
      size="md"
      header={{
        title: "Add Field for Person",
      }}
      primaryButton={{
        label: "Add Field",
        props: {
          onClick: () => save({ field, type: type?.value }),
          isDisabled: !field.length,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: onCancel,
        },
      }}
    >
      <InputFieldWithError
        labelText="Field Name"
        placeholder={"Enter field name"}
        type="string"
        errorMsg=""
        name="fieldName"
        onChange={(val) => setField(val.target.value)}
        value={field}
      />

      <FormControl py="2">
        <FormLabel marginBottom={1}>Field type</FormLabel>
        <DropdownWithSearch
          name="field-type"
          options={OPTIONS}
          placeholder="Select"
          value={type}
          onChange={(option) => setType(option as (typeof OPTIONS)[number])}
          controlStyle={{
            borderBottomLeftRadius: "0",
            borderTopLeftRadius: "0",
          }}
        />
      </FormControl>
    </IModal>
  );
}
